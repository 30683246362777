

.logo {
	margin-top: -10px !important;
	width: 190px !important;
	height: auto !important;
	border-radius: 0 !important;
}

@media screen and (max-width: 767px) {
	.logo {
		margin-top: -15px !important;
		width: 62px !important;
		height: auto !important;
		border-radius: 0 !important;
	}

	.logo-auth {
		margin-top: -15px !important;
		width: 132px !important;
		height: auto !important;
		border-radius: 0 !important;
	}

	a[disabled] {
		border-bottom: none !important;
		border-left: solid;
		border-width: 2.5px !important;
	}
}

button {
	all: unset;
}

#menu-toggle {
	color: #fff;
	font-size: 1.5em;
}

.myPaddingTop {
	padding-top: 5px;
}

.modalText p {
	font-size: 5em;
}

.myPaddingTop2 {
	padding-top: 40px;
}

.myTitle {
	font-size: 30px;
	padding-bottom: 30px;
}

#local_data .local p {
	margin: 0;
	font-size: 14px;
}

#local_data .data p {
	margin: 0;
	font-size: 14px;
}

.grayblock2 {
	background: #eee;
}

.instrucoes {
	margin-top: 30px;
}

.icon hr {
	border-color: #00bbff;
}

.icon h4 strong {
	font-size: 20px;
}

#email {
	color: black;
	text-decoration: none;
}

.navbar {
	min-height: 95px;
}

@media (max-width: 767px) {
	.navbar {
		min-height: 65px;
	}
}

.navbar-header {
	padding: 0 !important;
}

#formModal:hover {
	cursor: pointer;
}

.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0,0,0, 0.5);
	z-index: 10000;
	overflow: auto;
}

.popup_inner {
	position: absolute;
	left: 30%;
	right: 30%;
	top: 30%;
	bottom: 30%;
	margin: auto;
	background: white;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding: 20px;
	color: rgba(37, 64, 97, 1);
}

.optionRadio, .dataRadio, .paymentRadio {
	margin: 0 15px 25px;
}

.optionCheck {
	margin: 0 15px 25px;
}

.grayblock {
	background: rgba(6, 92, 164, 0.86);
	color: #fff;
}

.grayblock .icon p {
	color: #fff !important;
}

.grayblock2 {
	background: rgba(0, 187, 255, 0.70);
	color: #000;
}

.grayblock2 hr {
	color: rgba(6, 92, 164, 0.86)
}

.grayblock2 .icon p {
	color: #000 !important;
}

.junifeup {
	color: #379AB7;
}

.winning {
	color: #cf181f;
}

.bg-white  {
	background: #fff;
}

.information {
	margin-top: 10px !important;
}

.information small {
	font-size: 0.60em;
}

.company_img {
	flex: 0 0 100%;
  	max-width: 100%;
}

.buttons {
	display: flex;
	justify-content: center;
	align-items: center;

}

#banner .container .row {
	display: flex;
	justify-content: center;
}

.formacao-info p{
	color:white;
}

.formacao-button-right {
	margin-left: 1.5rem;
}

@media (max-width: 650px) {
	.formacao-button-right {
		margin-left: 0;
	}
}

.col-sm-12 .center .description {
	text-align: center;
	padding: 1.25em 0 !important ;
}

.center {
	text-align: center;
	vertical-align: middle;
	margin: 0 auto;
	padding: 0 2em;
}
.center-buttons{
	display: flex;
	min-width: 150px; /* Set a minimum width for the button */
	padding: 10px 15px;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
}

.center-buttons .btn{
	font-size: 20px !important;
 }
@media (max-width: 1200px) {
	.center {
		margin: 0;
		padding: 0;
	}
	.center-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
.col-sm-12 .description {
	padding-top: 225px !important;
}

.highlight-link-pink:hover strong, .highlight-link-pink:active strong{
    color: var(--pink);
}

.highlight-link-blue:hover strong, .highlight-link-blue:active strong{
	color: var(--blue);
}

.highlight-link-purple:hover strong, .highlight-link-purple:active strong{
    color: var(--purple);
}

a[disabled] {
	pointer-events: none;
	border-bottom:solid;
	border-width: 1px;
}


#buttonToInscricao {
	display: flex;
	justify-content: center;
}

#buttonToInscricao a {
	z-index: 2; 
	border-radius: 50px !important;
	overflow:hidden;
	border:none;
	background-color: transparent;
	width: 60px !important;
	height: 60px;
	display: flex;
	align-items: center;
	font-size: 32px;
	justify-content: center;
}

.division {
	border-color: white;
	border-width: 1px;
}
