.custom-input {
  border-radius: 10px;
  border: 1px solid lightgray;
  background-color: #ffffff;
}

.custom-input:focus {
  border-color: #212529 !important;
  box-shadow: 0 0 0 0.1rem rgba(33, 37, 41, 1);
}

.custom-input::placeholder {
 padding: 0;
 font-size: 1rem;
 color:rgb(100, 100, 100)
}

.required-label {
  color: red;
}


.application-btn {
  background-image: linear-gradient(to right, #a15ecc, #7656cc, #5a4ece, #4c6ad6, #4c6ad6, #4981db, #4981db, #42a8e8);
  color: #ffffff;
  width: fit-content;
  border-radius: 15px;
  font-weight: 400 !important;
  padding: 0.5rem 0.8rem;
}

.application-btn:hover {
  background-color: #212529;  
  background-image: none;
}

/* Override */
.modal-btn {
  background-color: #212529 !important;
}

.form-control {
  margin-left: 0;
  /* padding: 0; */
} 

.modal-btn:hover {
  border-color: #212529;
}

/* Bootstrap Override */
.popup-with-backcolor {
  background-color: #ffffff !important; /* Replace with your desired color */
  background-image: none;
}

.model-content {
  background-color: #ededed !important ; /* Replace with your desired color */
}