@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;500;800&display=swap');

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, p, .navbar, .brand, .btn-simple, a, td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Mulish', sans-serif;
}

:root {
  --pink: #A35DCE;
  --blue: #45A7E8;
  --purple: #4A6AD6;
  --dark: #011635;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-weight: 700;
  margin: 10px 0 10px;
}

h1, .h1 {
  font-size: 52px;
}

h2, .h2 {
  font-size: 36px;
}

h3, .h3 {
  font-size: 28px;
  margin: 20px 0 10px;
}

h4, .h4 {
  font-size: 22px;
  line-height: 30px;
}

h5, .h5 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
}

h6, .h6 {
  font-size: 14px;
  text-transform: uppercase;
}

p {
  font-weight: 400;
  font-size: 17px;
  line-height: 1.5;
}

body {
  font-family: 'Mulish', sans-serif;
}

a {
  color: black;
}

a:hover, a:focus {
  color: black;
  text-decoration: none;
}

a:focus, a:active {
  outline: 0;
}

.navbar {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.btn {border-radius:2px;}

.btn-hover, i {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.fa {
  text-align: center;
}

.fa:before {
vertical-align: middle;
}

.btn {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: #888888;
  color: #888888;
}

.btn:hover, .btn:focus, .btn:active, .btn.active, .open > .btn.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777;
}

.btn:disabled, .btn[disabled], .btn.disabled {
  background-color: transparent;
  border-color: #888888;
}

.btn.btn-fill {
  color: white;
  background-color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn.btn-fill:hover, .btn.btn-fill:focus, .btn.btn-fill:active, .btn.btn-fill.active, .open > .btn.btn-fill.dropdown-toggle {
  background-color: #777777;
  color: white;
  
}

.btn.btn-fill .caret {
  border-top-color: white;
}

.btn .caret {
  border-top-color: #888888;
}

.btn:hover, .btn:focus {
  opacity: 1;
  filter: alpha(opacity=100);
  outline: 0 !important;
}
.btn:active, .btn.active, .open > .btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}
.btn.btn-icon {
  padding: 8px;
}

.btn-neutral {
  border-color: white;
  color: white;
}

.btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .open > .btn-neutral.dropdown-toggle {
  background-color: transparent;
  color: white;
  border-color: white;
}

.btn-neutral:disabled, .btn-neutral[disabled], .btn-neutral.disabled {
  background-color: transparent;
}

.btn-neutral.btn-fill {
  color: white;
  background-color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus, .btn-neutral.btn-fill:active, .btn-neutral.btn-fill.active, .open > .btn-neutral.btn-fill.dropdown-toggle {
  background-color: white;
  color: white;
}

.btn-neutral.btn-fill .caret {
  border-top-color: white;
}

.btn-neutral .caret {
  border-top-color: white;
}

.btn-neutral:active, .btn-neutral.active, .open > .btn-neutral.dropdown-toggle {
  background-color: white;
  color: #888888;
}

.btn-neutral.btn-fill, .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus {
  color: #888888;
}

.btn-neutral.btn-simple:active, .btn-neutral.btn-simple.active {
  background-color: transparent;
}

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}


.btn-round.btn-icon {
  padding: 9px;
}

.btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px;
}

.btn-simple.btn-icon {
  padding: 8px;
}

.btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 700;
}

.btn-lg.btn-round {
  padding: 15px 30px;
}

.btn-lg.btn-simple {
  padding: 16px 30px;
}

.btn-sm {
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
}

.btn-sm.btn-round {
  padding: 6px 10px;
}

.btn-sm.btn-simple {
  padding: 7px 10px;
}

.btn-xs {
  font-size: 12px;
  border-radius: 3px;
  padding: 1px 5px;
}

.btn-xs.btn-round {
  padding: 2px 5px;
}

.btn-xs.btn-simple {
  padding: 3px 5px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.section {
  padding: 30px 0;
  position: relative;
  background-color: #FFFFFF;
}

.section-white {
  background-color: #FFFFFF;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  font-size: 23px;

}

.logo-container {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.navbar-transparent a {
  color: black;
  opacity: 1;
  filter: alpha(opacity=100);
}


.navbar-buttons {
  order: 1; /* Change order to 1 to place the rest of the content on the left */
}

.navbar-buttons .navbar-nav {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navbar-buttons .navbar-nav .nav-item {
  margin: 0 15px;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  left: 50%;
  right: 50%;
  position: absolute;
}


.logout-btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.logout-btn:focus {
  outline: none;
}

.nav-item.active a.nav-link {
  background: linear-gradient(to right, #a15ecc, #7656cc, #5a4ece, #42a8e8);
  -webkit-background-clip: text;
  color: transparent; 
  font-weight: bold;
}




.footer {
  flex-shrink: 0;
  background-color: white;
  line-height: 50px;
  height: 100px;
  width: 100%;
  position: relative;
}

.footer nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.footer nav > ul a:not(.btn) {
  color: #9a9a9a;
  display: block;
  margin-bottom: 3px;
}

.footer nav > ul a:not(.btn):hover, .footer nav > ul a:not(.btn):focus {
  color: #777777;
}

.footer .social-area {
  text-align: center;
}

.social-area {
  display: flex;
  float: right;
  margin-top: 10px;
  gap: 10px;
  font-size: 26px;
}

.linkedin-icon,
.facebook-icon,
.instagram-icon
{
  color:black !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 26px !important;
}


@media screen and (max-width: 387px) {
  .footer .copyright {
    margin-top: 10px !important;
  }

}

.footer .social-area h5 {
  padding-bottom: 10px;
}

.footer .social-area > a:not(.btn) {
  color: #9a9a9a;
  display: inline-block;
  vertical-align: top;
  padding: 15px 5px;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.footer .social-area > a:not(.btn):hover, .footer .social-area > a:not(.btn):focus {
  color: #777777;
}

.footer .copyright {
  color: #777777;
  padding: 5px 15px;
  margin: 20px 3px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
}

.juni-copy{
  color:black;
  font-size: 18px;
}

.footer hr {
  border-color: #dddddd;
}

.footer .title {
  color: #777777;
}

.footer:not(.footer-big) nav > ul {
  font-size: 16px;
}
.footer:not(.footer-big) nav > ul li {
  margin-left: 20px;
  float: left;
}
.footer:not(.footer-big) nav > ul a {
  padding: 10px 0;
  margin: 15px 10px 15px 0;
}

.footer-default {
  background-color: whitesmoke;
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}

.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}

.btn-social.btn-simple {
  padding: 15px 5px;
  font-size: 16px;
}

.btn-social.btn-simple .fa {
  font-size: 30px;
  position: relative;
  top: -2px;
  width: 36px;
}

.border-top {
  border-top: 1px solid #e2e2e2;
}

.landing-page p {
  line-height: 28px;
  font-size: 18px;
  font-weight: 400;
}

.landing-page .img-container {
  border-radius: 10px;
  overflow: hidden;
}

.landing-page .img-container img {
  width: 100%;
}

.landing-page .section-testimonials .carousel-control {
  left: -70px;
}

.landing-page .section-testimonials .carousel-control .fa {
  top: 40%;
}

.landing-page .section-testimonials .carousel-control.right {
  right: -40px;
  left: auto;
}

.landing-page .section-clients {
  padding: 80px 0;
}

.landing-page .section-clients .info .icon {
  background: transparent;
}

.landing-page .section-clients .icon i {
  color: #AAAAAA;
}

.landing-page .section-clients h3 {
  margin-top: 10px;
}

.landing-page .social-line {
  border-bottom: 1px solid #dddddd;
}

.landing-page .carousel-indicators {
  bottom: -50px;
}

.landing-page .carousel-inner > .item > img,
.landing-page .carousel-inner > .item > a > img {
  display: block;
  margin: 0 auto;
  height: auto;
}

.landing-page .carousel.fade {
  opacity: 1;
}

.landing-page .carousel.fade .item:first-child {
  top: auto;
  position: relative;
}

.landing-page .carousel.fade .item.active {
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index: 2;
}

.carousel-indicators li.active {
  width: 20px;
  height: 20px;
  margin: 0;
}

.carousel-indicators li {
  width: 20px;
  height: 20px;
  margin: 0;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 0 solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  color: #FFFFFF;
  line-height: 30px;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  height: 50px;
	font-size: 30px;
	letter-spacing: -1px;
	font-weight: 700;
}

.logo-container {
  margin-top: 10px;
}

.logo-container .logo img {
  width: 100%;
}

.landing-page .container, .landing-page-2 .container, .landing-page-3 .container {
  max-width: 3000px;
}

.header-text {
  font-weight: 500;
  color: black;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Figtree', sans-serif;
  width: 50%;
  margin: 0 !important;
  text-align: left !important;
}


.landing-page .navbar-top {
  top: 0;
  right: 0;
  left: 0;
}

.nav-link {
  color: white;
  z-index: 2000 !important;
}

.logo-btn {
  z-index: 1500;
  padding-left: 50px;
}

@media (max-width: 767px) {
  .logo-btn-auth {
    padding-left: 0 !important;
  }

  #navbar .container-auth {
    justify-content: center;
  }

  .middle {
    display: block;
    position: inherit;
  }
}

.landing-page .footer {
  padding: 20px 0;
}

.landing-page .description {
  padding: 20px;
  color: var(--dark);
}

.landing-page .description h2 {
  line-height: 1.6;
  font-weight: 300;
  padding-top: 100px;
  text-align: left;
}

.landing-page .description h5 {
  line-height: 1.6;
  font-weight: 600;
  font-size: 20px;
}

/* .landing-page .buttons {
  width: 100%;
  justify-content: center;
  margin-top: 80px;
  padding-top: 50px;
} */

.landing-page .buttons .btn-simple {
  font-size: 30px;
}

.landing-page .logos {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.landing-page .logos ul {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-top: 48px;
}

.landing-page .logos li {
  display: inline-block;
  margin: 0 17px;
}

.landing-page .section-presentation {
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}

.landing-page .section-presentation img {
  object-fit: contain;
  top: 100px;
  margin-bottom: 40px;
}

.landing-page .section-presentation .iphone-presentation {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.gradient-container {
  color: white;
  width: 100%;
  text-align: center;
}

#landing-page-cards .card {
  background: #f5f5f5;
  border-radius: 25px !important;
  color: var(--dark);
}

.juni-org {
  margin-right: 1px;
}

#formation-page {
  border-radius: 0 0 25px 25px;
  padding-bottom: 20px;
}

@media (max-width: 549px) {

  .description p {
    font-size: 16px;
  }

  .organizadores {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  #landing-page-cards .card {
    max-width: 200px;
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  #card-pm-page .card {
    margin-left: auto;
    margin-right: auto;
  }

  #landing-page-cards .card svg {
    width: 25px !important;
    height: 20px !important;
  }

  #landing-page-cards .new-tag {
    display: none;
  }

  #landing-page-cards .card li {
    padding-bottom: 0 !important;
    font-size: 14px !important;
  }

  #landing-page-cards .card-title {
    font-size: 15px !important;
  }

  .div-button-ins {
    padding-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .organizadores {
    margin-right: 0 !important;
  }
}

@media (max-width: 1002px){
  .description p {
    font-size: 16px;
  }

  .organizadores {
    width: 90% !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 986px) {
  .details {
    padding-left: 50px !important;
  }
}

@media (max-width: 1270px){
  #card-pm-page .card {
    max-width: 400px;
    max-height: 500px;
  }
}

#card-pm-page .card {
  min-width: 180px;
}

#landing-page-cards h6, li {
  color: var(--dark);
}

#landing-page-cards h6 {
  font-size: 20px;
  text-transform: none;
  text-align: left;
}

/* #landing-page-cards li {
  font-size: 18px;
  text-align: left;
} */

.card li i{
  font-size: 20px;
  text-align: left;
  padding-right: 15px;
}

#landing-page-cards ul {
  list-style: none;
}
.landing-page .full-background .info {
  position: relative;
  z-index: 20;
  padding: 80px 0;
}

.landing-page .full-background .info h1 {
  font-size: 40px;
  font-weight: 300;
}

.landing-page .full-background .info  a {
  position: relative;
  z-index: 10;
}

.landing-page .full-background .info .btn-lg {
  margin-top: 40px;
  padding: 14px 80px;
}

@media (max-width: 768px) {
  .insc-button {
    width: 50% !important;
  }

  .nav > li {
    text-align: center;
  }

  .navbar-toggle .icon-bar {
    background: white;
  }

  .navbar-toggle {
    padding: 16px 10px;
  }

  .landing-page .wrapper {
    text-align: center;
  }
  .landing-page .parallax-background img {
    display: none;
  }

  .btn-lg {
    font-size: 16px;
    padding: 14px 10px;
  }

  h5, .h5 {
    font-size: 20px;
  }

  .col-xs-offset-1 {
    margin-left: 0;
  }

  .landing-page .description {
    padding-top: 0;
  }

  .landing-page .description h2 {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  #buttonToInscricao {
    display: none !important;
  }

  .landing-page .buttons {
    margin: 20px 0;
  }

  .landing-page .logos li {
    display: block;
    margin-top: 10px;
  }

  .landing-page .logos ul {
    padding-top: 20px;
  }

  .landing-page .section-presentation {
    margin: 0;
  }

  .landing-page .section-presentation {
    height: auto;
  }

  .landing-page .carousel{
    min-height: 210px;
  }

  .footer nav.pull-left, .footer:not(.footer-big) nav > ul li, .pull-right {
    float: none !important;
  }
}

@media (min-width: 768px){
  .landing-page .navbar-top{
      padding-top: 10px;
  }
}

@media (max-width: 992px) {
  .section-demo {
    text-align: center;
    vertical-align: middle;
    padding: 80px 0 70px;
  }

  #organizadores {
    text-align: left;
    background-color: white;
    border-radius: 25px;
  }

  .second-column {
    margin-top: 40px !important;
    margin-left: 0;
  }

  .img-feup {
    width: 35em;
  }
}



@media (max-width: 1347px) and (min-width: 854px) {
  #formation-page .organizadores {
    height: 450px;
  }
}

@media (max-width: 854px) and (min-width: 768px) {
  #formation-page .organizadores {
    height: 540px;
  }
}

@media (max-width: 767px) {
  #formation-page .organizadores {
    height: fit-content;
  }
}

.organizadores-logo {
  height: 120px;
  text-align: center;
  padding-top: 20px;
}

.organizadores-logo img {
  max-height: 100px;
}

@media (max-width: 50px) {
  .landing-page .center .description .font-weight-bold {
    display: none;
  }

  #buttonToInscricao {
    display: none !important;
  }

}

@media (max-width: 400px) {
  .landing-page .description h2 {
    font-size: 30px;
    padding-bottom: 20px;
  }

  .landing-page .center {
    padding: 0;
  }
}

.fixed-plugin {
  position: fixed;
  top:30px;
  right: 40px;
  z-index: 1031;
}

.fixed-plugin .fa-cog{
  color: #ccc;
  width: 100%;
}

.fixed-plugin .dropdown-menu {
  right: -10px;
  left: auto;
  top: 40px;
}

.fixed-plugin .dropdown-menu:after, .fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}

.fixed-plugin .fa-circle-thin {
  color: #FFFFFF;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > li > a {
  padding-left: 10px;
  padding-right: 10px;
}

.fixed-plugin .dropdown-menu > .active > a, .fixed-plugin .dropdown-menu > .active > a:hover, .fixed-plugin .dropdown-menu > .active > a:focus {
  background: none;
  color: #777777;
}

.fixed-plugin li.active div {
  border: 8px solid #ccc;
}

.fixed-plugin .btn {
  margin: 10px 15px 0;
  color: #FFFFFF;
}

.fixed-plugin .btn:hover {
  color: #FFFFFF;
}

.fixed-plugin .badge {
  cursor: pointer;
  margin-right: 5px;
  position: relative;
  top: 5px;
  display: inline-block;
}

.fixed-plugin .badge.active, .fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: transparent;
}

@media (max-width:1170px) {
  .fixed-plugin {
    top: 90px;
    right: 10px;
  }

  .main .container {
    margin-bottom: 50px;
  }

  #video_background {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .navbar-nav > li > .dropdown-menu, .dropdown-menu{
    display: block;
    margin: 0;
    padding: 10px;
    z-index: 9000;
    position: absolute;
    -webkit-border-radius: 2px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 0;
    -webkit-filter: alpha(opacity=0);
    -moz-filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    -o-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }

  .navbar-nav > li.open > .dropdown-menu, .open .dropdown-menu {
    -webkit-transform-origin: 29px -50px;
    -moz-transform-origin: 29px -50px;
    -o-transform-origin: 29px -50px;
    -ms-transform-origin: 29px -50px;
    transform-origin: 29px -50px;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    -moz-filter: none;
    -o-filter: none;
    filter: none;
  }

  .dropdown-menu:before {
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 100%;
    margin-left: -60%;
    top: -11px;
  }

  .dropdown-menu:after {
    border-bottom: 11px solid #FFFFFF;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 100%;
    margin-left: -60%;
    top: -10px;
  }
}

@media (max-width: 520px) {
  h5, .h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.formacao-intro {
  background-color: white;
  text-align: left;
  color: var(--dark);
  /*items one below other*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  padding: 25px;
}

.table td, .table th{
  padding: 0;
  vertical-align: middle;
  border-top: 1px solid #dee2e6 !important;
}



.table tr:first-child th{
  border-top: none !important;
}


.formacao-information{
  background-color: white;
  text-align: left;
  color: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding: 35px;
  padding-top: 45px;
}

.formacao-information h4{
  font-family: 'Figtree', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}

.formacao-information h2{
  font-family: 'Figtree', sans-serif;
  font-size: 38px;
  margin: 0;
  margin-bottom: 30px;
}

.formacao-information h5{
  font-family: 'Figtree', sans-serif;
  font-size: 20px;
  margin: 0;
  margin-bottom: 5px;
  margin-top: 35px;

}

.formacao-information p{
  font-family: 'Figtree', sans-serif;
  font-size: 20px;
  margin: 0;
  font-weight: 300;
  line-height: normal;
}


.formacao-last-information{
  background-color: white;
  text-align: left;
  color: var(--dark);
  /*items one below other*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding: 30px;
}

.first-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding: 35px;
  padding-top: 45px;
}

.formacao-last-information p{
  font-family: 'Figtree', sans-serif;
}

.organizadores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.organizadores p {
  font-family: 'Figtree', sans-serif !important;
  font-size: 20px !important;
}

.formacao-last-information li{
  list-style-type: none;
  font-size: 20px;
  font-family: 'Figtree', sans-serif;
  padding-top: 20px;

}

.last-information{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.two-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 60px;
}


.container-formations{
  background-color: white;

}


#pm-organizadores {
  padding-top: 30px;
}

.card {
  border-radius: 25px;
  margin-bottom: 10px;
}

.details li {
  margin-bottom: 1rem;
  font-size: 18px;
}

.details {
  padding-left: 40px;
}

.novo-tag-pm1, .novo-tag-pm2, .novo-tag-ls {
  color: white;
  text-align: center;
  border-radius: 25px;
  padding-right: 7px;
  padding-left: 7px;
}

.novo-tag-pm2 {
  background-color: #45A7E8;
}

.novo-tag-ls {
  background-color: #4A6AD6;
}

.novo-tag-pm1 {
  background-color: #A35DCE;
}

.card ul {
  padding-left: 0;
  list-style: none;
  padding-top: 15px;
}

.card li {
  padding-bottom: 8px;
}

.card svg {
  width: 40px;
  height: 20px;
  padding-right: 10px;
}

.card span {
  font-size: 80%;
}

.card img {
  border-radius: 25px 25px 0 0;
}

.insc-button {
  text-align: center;
  background: #F9F9F9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-weight: 900;
  font-size: 20px;
  padding: 15px;
  width: 100%;
}

.insc-button-disabled {
  opacity: 0.7;
}

.insc-button-pink, .insc-buttonDisabled-pink {
  border: 1px solid var(--pink);
  color: var(--pink);
}

.insc-button-blue, .insc-buttonDisabled-blue {
  border: 1px solid var(--blue);
  color: var(--blue);
}

.insc-button-purple, .insc-buttonDisabled-purple {
  border: 1px solid var(--purple);
  color: var(--purple);
}

.insc-button-pink:hover, .insc-button-pink:active {
  background-color: var(--pink);
  color: #F9F9F9;
}

.insc-button-blue:hover, .insc-button-blue:active {
  background-color: var(--blue);
  color: #F9F9F9;
}

.insc-button-purple:hover, .insc-button-purple:active {
  background-color: var(--purple);
  color: #F9F9F9;
}

.div-button-ins {
  text-align: center;
  width: 100%;
}

.modal-content {
  border-radius: 25px;
  background-color: #F9F9F9;
}

.popup-with-backcolor {
  background-image: linear-gradient(180deg, rgba(163, 93, 206, 0.25) 0%, rgba(91, 77, 206, 0.25) 35.05%, rgba(74, 106, 214, 0.25) 61.71%, rgba(69, 167, 232, 0.25) 100%);
  background-color: rgba(192,192,192,1);
}

.form-control {
  border-radius: 15px;
  border: none;
  color: black;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
}
.form-control::placeholder {
  color: black !important;
  opacity: 1;
  padding-left: 10px;
  font-size: 13px;
}

.modal-btn {
  border-color: var(--dark);
  opacity: 1;
  background-color: black;
  color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-size: 18px;
  padding: 5px 20px 5px 20px;
  font-weight: 100;
  z-index: 1000;
}


.modal-btn:hover {
  border-color: var(--dark);
  background: linear-gradient(to right, #a15ecc 0%, #7656cc 30%,#5a4ece 50%, #42a8e8 100%);
  font-weight: 400;
  color: white;
}

#span-date {
  padding-left: 0 !important;
  font-size: 16px;
}

.payment-div {
  padding-left: 0;
}

.date-div {
  padding-left: 1rem;
}

.dateRadio, .paymentRadio {
  margin-bottom: 0;
}

#landing-page-title h5 {
  color:white;
}

#descricao {
  padding-top: 60px;
  background-color: white;
}

.button-div {
  text-align: center;
  padding-bottom: 15px;
}

#landing-page-title .card-title {
  font-size: 20px;
  padding-left: 5px;
}

#landing-page-title .card-body {
  text-align: left;
}

.card {
  background-color: #f5f5f5;
}

.label-input {
  padding-bottom: 12px;
}

input[type=radio] + label:before {
  border: 2px solid var(--dark);
}

input[type=radio]:checked + label:after {
  border-radius: 14px;
  background-color: var(--dark);
}

input[type=checkbox]:checked + label:after {
  background-color: var(--dark);
  border-radius: 9px;
}

#registerForm {
  padding-top: 20px;
}

#rejection button, #accept button{
  text-align: right;
  padding-right: 15px;
}

#descricao-row {
  margin-right: 20px;
  margin-left: 20px;
}

.description p{
  font-size: 24px;
  font-family: 'Figtree', sans-serif;
  font-weight: 500;
}

.description{
  padding-bottom: 100px;
}

.programa-button {
  display: inline-block;
  padding: 4px 10px;
  background-color: black; /* Match the button color */
  color: white; /* Text color */
  text-align: center;
  text-decoration: none;
  font-weight: 300;
  border-radius: 14px; /* Gives the pill shape */
  font-size: 18px; /* Match your font-size */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  margin-left: 10px;
}

.programa-button:hover,
.programa-button:focus {
  background-color: #555; /* Darker background on hover/focus */
  color: #ddd; /* Light text on hover/focus */
  text-decoration: none; /* Ensures the text isn't underlined on hover/focus */
}


.new-tag {
  color: white;
  border-radius: 25px;
  padding: 5px 10px;
}

.new-tag-pink {
  background: var(--pink);
}

.new-tag-blue {
  background: var(--blue);
}

.new-tag-purple {
  background: var(--purple);
}

.icon-pink {
  color: var(--pink);
}

.icon-blue {
  color: var(--blue);
}

.icon-purple {
  color: var(--purple);
}

.cardButton-ul {
  font-size: 20px;
}

#login-page {
  padding-bottom: 20px;
  height: 700px;
}

#login-container {
  background: rgba(237,237,237,255);
  color: var(--dark);
  border-radius: 35px;
}

#login-page button {
  border: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #000000;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
  /*align to the left*/
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

}


.buttons-lgn-rdf{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0; 
}




.btn-reset{
  display: inline-block;
  min-width: 150px; /* Set a minimum width for the button */
  padding: 10px 15px;
  text-align: center;
  white-space: nowrap;
  overflow: visible; /* Ensure overflow is visible during testing */
  border: none; /* Assuming no border */
  cursor: pointer;
}


#login-page button:hover {
  background-color: white;
  color: var(--dark);
  transition: 0.3s;
}

#login-page h2 {
  margin-bottom: 50px;
}

#login-page span {
  border-radius: 15px 0 0 15px;
  height: 40px;
  border: none;
  font-size: 20px;
  color: #000000;
  padding-right: 10px !important;
  padding-left: 15px !important;
}


#login-page .input-group {
  padding-bottom: 50px;
  /*put all items in the same height*/
  display: flex;
  align-items: center;

  
}

.login-title-container {
  display: flex;
  justify-content: left;
  padding-left: 6%;
  padding-top: 50px;
  color: #000000;
}

.input-group input[type="email"]:focus,
.input-group input[type="password"]:focus,
.input-group input[type="text"]{
  outline-color: transparent !important;
  box-shadow: none !important; 
}


.input-group input[type="email"],
.input-group input[type="password"],
.input-group input[type="text"]{
  border-radius: 0px 15px 15px 0px !important;
  padding: 10px;
  height: 40px;
  border: none;
}

.input-group input[type="email"]::placeholder,
.input-group input[type="password"]::placeholder,
.input-group input[type="text"]::placeholder {
  color: black;
  font-size: 12px;
}



#navbar i {
  color: white;
}

#backoffice-page-formations .table, 
#sponsors-page .table{
  width: 70%;
  background-color: rgba(237,237,237,255);
  border-radius: 50px;
}


#backoffice-page-formations .table th,
#sponsors-page .table th{
  font-size: 25px;
  border-top: none;
  padding: 25px;
}

.sponsor-title{
  color:black;
  margin-top: 5%;
  margin-bottom: 3%;
  font-size: 4em;
  font-family: 'Figtree', sans-serif;
}

.formations-title{
  color:black;
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 4em;
  font-family: 'Figtree', sans-serif;
}

#backoffice-page-formations .table td{
  font-size: 20px;
}

#backoffice-page-formations .add-formation {
  font-size: 15px;
  border: 2px solid white;
  border-radius: 25px;
  padding:10px;
  font-weight: bold;
}

#backoffice-page-formations .add-formation:hover {
  border: 2px solid var(--dark);
  color: var(--dark);
}

#backoffice-page-formations .add-row,
#sponsors-page .add-row{
  justify-content: center;
  padding-bottom: 60px;
}

#backoffice-page-formations a:hover,
#sponsors-page a:hover{
  text-decoration: underline;
}

#addFormation-page .check-div,
#editFormation-page .check-div{
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
}


.editSponsor-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(237,237,237,255);
  border-radius: 50px;
}

.container-sponsor{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(237,237,237,255);
  border-radius: 50px;

}

.edit-sponsor-title{
  font-size: 2.5em;
  color:black;
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
  margin-left: 10px;
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row; /* Set flex direction to row */
}

.buttons-wrapper button {
  margin: 0 10px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 200px;
}



#addFormation-page .form-control,
#editFormation-page .form-control,
#addSponsor-page .form-control,
#editSponsor-page .form-control {
  border-radius: 20px;
  background: white;
  border: none;
  color: black;
}

#addFormation-page input[type=text],
#editFormation-page input[type=text],
#addSponsor-page input[type=text],
#editSponsor-page  input[type=text] {
  height: 50px !important;
  border-radius: 20px;
  border: none;
}

#addFormation-page .form-control textarea,
#editFormation-page .form-control textarea,
#addSponsor-page .form-control textarea,
#editSponsor-page .form-control textarea {
  height: auto;
  border-radius: 25px;
}


#addFormation-page input[type=file],
#editFormation-page input[type=file],
#addSponsor-page input[type=file],
#editSponsor-page  input[type=file]{
  background: none;
  border:none;
  border-radius: 0;
}


#addFormation-page label,
#editFormation-page label,
#addSponsor-page label,
#editSponsor-page label{
  font-size: 18px;
  color: black;
  font-weight: 600;
  font-family: 'Figtree', sans-serif;
  margin-left: 15px;
}

#addFormation-page .actions-div,
#editFormation-page .actions-div,
#addSponsor-page .actions-div,
#editSponsor-page  .actions-div{
  text-align: center;
}

#addFormation-page .buttons-div, #addSponsor-page .buttons-div {
  padding: 20px 20px 15px;
}




.btn-secondary {
  border: 2px solid var(--dark);
  color: var(--dark);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-size: 18px;
}

.btn-secondary:hover {
  background: var(--dark);
  color:white;
  border: 2px solid var(--dark);
}

form {
  width: 100%;
}

.needs-validation{
  width: 100%;

}

.container-addSponsor{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(237,237,237,255);
  border-radius: 50px;
  padding: 50px;

}


.container-addFormation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(237,237,237,255);
  border-radius: 50px;
  padding: 50px;
}


.container-editFormation{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(237,237,237,255);
  border-radius: 50px;
  padding: 50px;
}

#editFormation-page hr {
  border-top: 1px solid var(--dark);
  opacity: 0.6;
}

#editSponsor-page .alert{
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.invalid-feedback {
  color: #ab2632 !important;
  font-size: 90% !important;
}

#addFormation-page .organizadores {
  margin-top: 10px !important;;
}

.loggout-btn{
  border: 2px solid var(--dark);
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  display: flex;
  justify-content: right;
  align-items: center;
  right: 30px;
  top: 30px;
  position: absolute;
  margin-top: 0;
}

.loggout-btn:hover {
  background: #000000;
  color: white;
  border: 2px solid var(--dark);
  transition: all 0.5s ease-in-out;
}
@media (max-width: 1250px){
  .two-cards{
    flex-direction: column;
    align-items: center;
  }

  .formacao-information{
    width: 90% !important;
    align-items: center !important;
  }

  .new-card-container--large{
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 768px) {
  #addFormation-page .check-div, #editFormation-page .check-div {
    text-align: left !important;
  }
  #login-page button{
    font-size: 16px;
  }
  .editSponsor-container{
    width: 80%;
  }
  .loggout-btn{
    border: none;
    padding: 0;
    position: static; /* Change position to static to make it not fixed */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .two-cards{
    flex-direction: column;
    align-items: center;
  }
  .formacao-information{
    width: 100%;
    align-items: center !important;
  }
  .new-card-container--large{
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  #descricao{
    padding: 0px !important;
  }
  .description{
    padding-bottom: 0;
  }
  .programa-button{
    margin-top: 20px;
  }
  .header-text{
    width: 80%;
  }
  .formacao-last-information ul{
    padding-left: 0;
  }
  .last-information{
    width: 90%;
  }
  .login-title-container{
    padding-left: 10px;
    
  }
}

#registerForm .custom-control-input.is-invalid~.custom-control-label, .was-validated .custom-control-input:invalid~.custom-control-label {
  color: var(--dark);
}

#registerForm .custom-control-input.is-valid~.custom-control-label, .was-validated .custom-control-input:valid~.custom-control-label {
  color: var(--dark);
}

.navbar-nav {
  cursor: pointer;
}

#formationRow:hover, #sponsorRow:hover {
  font-weight: bolder;
  cursor: pointer;
  background-color: rgba(156, 156, 156, 0.3);
}
#sponsorRow, .table td{
  padding: 25px !important;
  vertical-align: middle;
  border-top: 1px solid #dee2e6 !important;
}

tbody th{
  border-top: 1px solid #dee2e6;
  border-radius: 0px 0px 0px 45px !important;
}


tbody tr:last-child td:last-child {
  border-top: 1px solid #dee2e6;
  border-radius: 0px 0px 45px 0px !important;
}
tbody tr:last-child td:first-child {
  border-top: 1px solid #dee2e6;
  border-radius: 0px 0px 0px 45px !important;
}
tbody tr:not(:last-child) th{
  border-top: 1px solid #dee2e6;
  border-radius: 0px 0px 0px 0px !important;
}

#backoffice-page-participants {
  display: flex;
  flex-direction: column; /* Ensure items stack vertically */
  justify-content: center;
  align-items: center;
  width: 99%;
  margin-left: auto;
  margin-right: -5px;

}


#backoffice-page-participants .tablediv {
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  border-radius: 45px;
  color: black;
  font-family: 'Figtree', sans-serif !important;
  padding-top: 8px;
  padding-bottom: 0px;
}

#backoffice-page-participants .tablediv th{
  font-size: 18px;
  border-top: none;
  vertical-align: middle !important;
  padding-top: 0px !important;
}






.form-control {
  margin-bottom: 5px; 
}


.botao {
  display: flex;
  position: fixed;
  margin-left: -30px;
  margin-top: -10px;
  z-index: 100;
}

.parent-container{
  display: flex !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-participantes{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000;
  border-color: #000000;
  border: 2px solid #000000;
  padding: 5px 20px 5px 20px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  margin-left: auto;
  margin-right: 20px;
}

.button-participantes:hover {
  background-color: #000000;
  color: white;
  border: 2px solid #000000;
  transition: all 0.5s ease-in-out;
}













