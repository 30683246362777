@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;800&display=swap');

.homepage-container {
  border-radius: 20px;
  width: 80%;
  min-height: 600px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage-container-no-formations{
  background-color: rgba(237,237,237,255);
  width: 70%;
  min-height: 540px;
  margin: 100px auto;
}

.no-formations{
  display: none;
}

.title {
  font-size: 60px; 
  color: black;
  font-family: 'Figtree', sans-serif;
  font-weight: 700; 
  text-align: center;
  text-transform: uppercase;
}

.texto-inscrever, .texto-fica-atento {
  background: linear-gradient(to right, #a15ecc, #7656cc, #5a4ece, #42a8e8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.card-left {
  transform: translateX(-137%) scale(0.67);
  opacity: 0.7;
}

.card-middle {
  transform: translateX(-50%) scale(1.1);
  opacity: 1;
  z-index: 2;
}

.card-right {
  transform: translateX(37%) scale(0.67);
   opacity: 0.7;
}
.card-outside{
  display: none;
}

.dots-container {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.card-container {
  position: absolute;
  left: 50%;
  transition: transform 0.5s ease, opacity 0.5s ease;
  will-change: transform, opacity;
  padding: 20px;
}

#landing-page-cards {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 600px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #717171;
}

@media (max-width: 767px) {
  .title {
    font-size: 34px;
    margin-top: 20%;
  }

}
/* Base styles for new-card-container - already defined in your CSS */

/* Mobile devices */
@media screen and (max-width: 767px) {
  #landing-page-cards {
    display: flex;
  }

  .homepage-container{
    width: 100%;
    overflow-x: hidden;
  }

  .card-container{
    width: 100%;
    margin-top: -130px;
  }

  .new-card-container {    
    margin: 0; /* Reset margin */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
    display: flex;
  }

  .card-middle{
    transform: translateX(-430%) scale(0.8);
  }


  .card-right{
    transform: translateX(0%) scale(0.5);
  }

  .card-left{
    transform: translateX(-600%) scale(0.5);
  }

  .dot{
    margin-top: -130px;
  }
  .homepage-container-no-formations{
    width: 85%;
  }

}
