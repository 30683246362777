body,
html {
  margin: 0;
  height: 100%;
  position: relative;
}

.not-found {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(to right, #a15ecc, #7656cc, #5a4ece, #4c6ad6, #4c6ad6, #4981db, #4981db, #42a8e8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.not-found h1 {
  font-size: 15em;
  font-weight: 700;
  transform: scaleY(0.8) scaleX(0.9);
  color: #ffffff;
  padding: 0;
  margin: 0;
  margin-top: -1rem;
}

.not-found p {
  font-size: 2rem;
  color: #ffffff;
  margin-top: -1.5rem;
}

.not-found a {
  margin-top: 1.5rem;
  font-size: 1.2rem;
  display: inline-block;
  padding: 10px 15px;
  color: #ffffff;
  border-radius: 15px;
  cursor: pointer;
}

.not-found a div {
  width: fit-content;
  border-bottom: 1px solid #ffffff;
}

.not-found a:hover {
  background-color: #ffffff;
  color: #3b3897;
}


@media (max-width: 768px) {

  /* Adjust this value based on where you want the breakpoint to be */
  .not-found h1 {
    font-size: 8rem;
    /* Smaller font size on mobile devices */
  }

  .not-found p {
    font-size: 1.2rem;
    margin-top: -0.5rem;
    /* Smaller font size on mobile devices */
  }

  .not-found a {
    font-size: 1rem;
    margin-top: 0;
  }

  .not-found {
    margin-top: -60px;
    /* Less margin on top on mobile devices */
  }
}