.new-card-link {
    text-decoration: none;
    color: inherit; /* Ensures the link color does not override card text color */
  }

.new-card-container {
  border-radius: 35px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 380px; 
  height: 500px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align the card body at the bottom */
  position: relative; /* Needed for absolute positioning of the tag */
}

.new-card-container--large{
  width: 550px;
  height: 670px;
  margin: 0;
}

  
  .new-card-body {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent background for the text */
    padding: 5px;
    border-radius: 25px;
    width: 90%;
    margin: 0 auto; /* Center the text container */
    height: 15%;
    margin-bottom: 15px;
    color: black;
  }
  
  .new-card-title {
    text-align: left;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-left: 10px;
    padding-top: 8px;
    letter-spacing: 0.3px;
}
  
  .new-card-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #FFC107;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 0.8rem;
  }
  
  .new-card-info {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
  
  .new-card-info li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 12px;
    padding-left: 10px;
  }

  /*li last child put on right*/
  .new-card-info li:last-child {  
    position:absolute;
    right: 8%;
    bottom: 7%;
  }
  
  .circle-price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; 
    height: 40px; 
    background-color: black;
    border-radius: 50%; 
    color: white; 
    font-size: 14px; 
    font-weight: 700;
  }

  .text{
    font-size: 14px;
    font-weight: 700;
    margin-left: 4px;
  }
  
  .btn-hover, i {
    margin-bottom: 2px;
    margin-top: 2.5px;
  }

  .card-date-info{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
    margin-left: 10px;
  }

  .date{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .time{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date-text{
    margin-left: 5px;
    margin-top: 1px;
    font-weight: 700;
  }

  .time-text{
   margin-left: 5px;
   margin-top: 1px;
   font-weight: 700;
  }

  .new-card-icon::before {
    font-weight: 900 !important;
  }
  